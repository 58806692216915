var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('appic-page-title-bar',{ref:"breadcrumbBar"}),_c('app-section-loader',{attrs:{"status":_vm.loader}}),_c('v-container',{attrs:{"fluid":"","grid-list-xl":"","pb-0":"","pt-0":"","pl-5":"","pr-4":""}},[_c('v-layout',{attrs:{"row":""}},[_c('app-card',{attrs:{"fullBlock":true,"colClasses":"xl12 lg12 md12 sm12 xs12"}},[_c('v-flex',{staticClass:"align-center px-1 pb-0 pt-3",attrs:{"xs12":"","lg6":"","md8":""}},[_c('v-sheet',{staticClass:"dense-inputs"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex flex-row float-right",attrs:{"cols":"12","lg":"12","md":"12","xs":"12"}},[_c('v-text-field',{staticClass:"mt-2 mb-0 force-text-left",attrs:{"label":_vm.$t('message.filterResults'),"value":_vm.searchTerm,"autocomplete":"off","prepend-inner-icon":"mdi-filter-outline"},on:{"change":function($event){_vm.searchTerm = $event}}}),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.loading.filterResults},on:{"click":_vm.filterResults}},[_vm._v(_vm._s(_vm.$t('message.filter')))]),_c('v-btn',{staticClass:"ml-3",on:{"click":_vm.resetSearch}},[_c('v-icon',[_vm._v("refresh")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){return _vm.openCreateDataFilterDialog()}}},[_c('v-icon',_vm._g({attrs:{"small":"","dark":""}},on),[_vm._v("ti ti-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('message.new')))])])],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.settings,"absolute":"","opacity":"0.15"}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"40","width":"6"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-0 appic-table-light specification-table",attrs:{"footer-props":{
                            itemsPerPageOptions: [20,50,-1],
                            showCurrentPage: true,
                            showFirstLastPage: true
                        },"headers":_vm.headers,"items":_vm.allStandardDataFilters,"search":_vm.searchTerm,"options":_vm.tableOptions,"calculate-widths":"","dense":"","fixed-header":"","id":"dataFiltersTable","item-key":"Condition.id"},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.Condition.method_title",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Condition.method_title))])]}},{key:"item.Condition.filter_title",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.Condition.filter_title))])]}},{key:"item.Condition.condition_field",fn:function(ref){
                        var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.Condition.condition_field))])]}},{key:"item.Condition.id",fn:function(ref){
                        var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"icon":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('message.moreActions')))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){_vm.openEditDataFilterDialog(item.Condition.id, (item.Condition.method_title + ' -> ' + item.Condition.filter_title))}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black lighten-1","small":""}},[_vm._v("edit")]),_vm._v(_vm._s(_vm.$t('message.updateSetting'))+" ")],1),_c('v-list-item',{staticClass:"font-sm",on:{"click":function($event){_vm.deleteDataFilter(item.Condition.id, (item.Condition.method_title + ' -> ' + item.Condition.filter_title))}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"red lighten-1"}},[_vm._v("cancel")]),_vm._v(_vm._s(_vm.$t('message.deleteDataFilter')))],1)],1)],1)],1)]}}])})],1)],1)],1)],1),_c('AddStandardDataFilter',{attrs:{"data-filter-id":_vm.selectedDataFilterId,"data-filter-name":_vm.selectedDataFilterName,"dialog":_vm.editDialog,"page-key":_vm.pageKey,"update-mode":_vm.updateMode},on:{"update:dialog":function($event){_vm.editDialog=$event},"dialog-closed":_vm.editDialogClosed,"update-done":_vm.dataFilterUpdated,"create-done":_vm.dataFilterCreated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }